import React, { Component, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import MarkdownWithHtml from 'react-markdown/with-html'
import ReactToPrint from 'react-to-print'

import styles from './recipeDetails.module.css'
import { content as pageStylesContent } from './pageDetails.module.css'
import { grid as postsStylesGrid } from './postsGrid.module.css'

import Image from './image'
import LinkIcon from './linkIcon'
import PostCard from './postCard'

class PrintableRecipe extends Component {
  render() {
    const { recipe, location } = this.props
    const { html, frontmatter } = recipe

    return (
      <section aria-label="Printable recipe" className="visuallyhidden">
        <h1>{frontmatter.title}</h1>
        <h2>Ingredients</h2>
        <MarkdownWithHtml source={frontmatter.ingredients} escapeHtml={false} />
        <h2>Directions</h2>
        <MarkdownWithHtml source={html} escapeHtml={false} />
        <h2>Information</h2>
        <ul>
          {frontmatter.yield && <li>Yield: {frontmatter.yield}</li>}
          {frontmatter.prep_time && <li>Prep Time: {frontmatter.prep_time}</li>}
          {frontmatter.cooking_time && (
            <li>Cooking Time: {frontmatter.cooking_time}</li>
          )}
          {frontmatter.difficulty && (
            <li>Difficulty: {frontmatter.difficulty}</li>
          )}
          <li>URL: {location.href}</li>
        </ul>
      </section>
    )
  }
}

PrintableRecipe.propTypes = {
  recipe: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

const RecipeDetails = ({ pageContext, recipe, location }) => {
  const { html, frontmatter } = recipe
  const printComponentRef = useRef()

  return (
    <>
      <div className={styles.root}>
        <div className={styles.wrapper} tabIndex={0}>
          {frontmatter.featured_image && (
            <Image
              node={frontmatter.featured_image.src}
              alt={frontmatter.featured_image.alt}
              className={classNames('framed-image', styles.image)}
            />
          )}
          <header className={styles.header}>
            <h1 className={classNames('title', styles.title)}>
              {frontmatter.title}
            </h1>
            {frontmatter.pairing && (
              <p className={classNames('subtitle', styles.subtitle)}>
                Pairs Perfectly with{' '}
                <AniLink
                  fade
                  to={frontmatter.pairing.childMarkdownRemark.fields.slug}
                  className={styles.cardLink}
                >
                  KRIS{' '}
                  {frontmatter.pairing.childMarkdownRemark.frontmatter.title}
                </AniLink>
              </p>
            )}
            <div className={styles.share}>
              <p>
                Share
                <span className="visuallyhidden"> this pairing recipe</span>:
              </p>
              <ul>
                <li>
                  <a
                    href={encodeURI(
                      `https://www.facebook.com/sharer/sharer.php?u=${location.href}`
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkIcon label="Facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href={encodeURI(
                      `https://twitter.com/intent/tweet?text=${frontmatter.title}&url=${location.href}&via=kriswines`
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkIcon label="Twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href={encodeURI(
                      `https://pinterest.com/pin/create/button/?url=${
                        location.href
                      }&media=${frontmatter.featured_image &&
                        location.origin +
                          frontmatter.featured_image.src
                            .publicURL}&description=${frontmatter.title}`
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkIcon label="Pinterest" />
                  </a>
                </li>
                <li>
                  <a
                    href={encodeURI(
                      `mailto:?subject=KRIS Wine Pairing Recipe: ${frontmatter.title}&body=${frontmatter.title}:\n\n ${location.href}`
                    )}
                  >
                    <LinkIcon label="Email" />
                  </a>
                </li>
              </ul>
            </div>
          </header>
          <section
            aria-labelledby="section-heading-ingredients"
            className={styles.section}
          >
            <header className={styles.sectionHeader}>
              <ul className={styles.meta}>
                <li>
                  <ReactToPrint
                    copyStyles={false}
                    trigger={() => (
                      <button className={styles.print}>
                        Print
                        <LinkIcon label="Print" />
                      </button>
                    )}
                    content={() => printComponentRef.current}
                  />
                </li>
                {frontmatter.yield && (
                  <li>
                    <span>Yield:</span> {frontmatter.yield}
                  </li>
                )}
                {frontmatter.prep_time && (
                  <li>
                    <span>Prep Time:</span> {frontmatter.prep_time}
                  </li>
                )}
                {frontmatter.cooking_time && (
                  <li>
                    <span>Cooking Time:</span> {frontmatter.cooking_time}
                  </li>
                )}
                {frontmatter.difficulty && (
                  <li>
                    <span>Difficulty:</span> {frontmatter.difficulty}
                  </li>
                )}
              </ul>
              <h2
                id="section-heading-ingredients"
                className="title title--small"
              >
                Ingredients
              </h2>
            </header>
            <MarkdownWithHtml
              source={frontmatter.ingredients}
              escapeHtml={false}
              className={pageStylesContent}
            />
          </section>
          <section
            aria-labelledby="section-heading-directions"
            className={styles.section}
          >
            <h2 id="section-heading-directions" className="title title--small">
              Directions
            </h2>
            <MarkdownWithHtml
              source={html}
              escapeHtml={false}
              className={pageStylesContent}
            />
          </section>
          {frontmatter.related_recipes.length > 0 && (
            <aside
              className={styles.section}
              aria-labelledby="section-heading-related-recipes"
            >
              <h2
                className="title title--small"
                style={{ textAlign: 'center' }}
                id="section-heading-related-recipes"
              >
                You May Also Like These Recipes
              </h2>
              <div className={postsStylesGrid}>
                {frontmatter.related_recipes.map(recipe => (
                  <PostCard
                    key={recipe.childMarkdownRemark.id}
                    post={recipe.childMarkdownRemark}
                  />
                ))}
              </div>
              <div style={{ textAlign: 'center' }}>
                <AniLink
                  fade
                  to={pageContext.parentSlug}
                  className="button button--inverse"
                >
                  Back to all Recipes
                </AniLink>
              </div>
            </aside>
          )}
        </div>
      </div>
      <PrintableRecipe
        ref={printComponentRef}
        recipe={recipe}
        location={location}
      />
    </>
  )
}

RecipeDetails.propTypes = {
  pageContext: PropTypes.object.isRequired,
  recipe: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default RecipeDetails
