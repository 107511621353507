import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import RecipeDetails from '../components/recipeDetails'

export default ({ pageContext, data, location }) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark

  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        seoTitle={frontmatter.seo_title}
        description={frontmatter.seo_description}
      />
      <RecipeDetails
        pageContext={pageContext}
        recipe={markdownRemark}
        location={location}
      />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        seo_title
        seo_description
        featured_image {
          src {
            publicURL
            childImageSharp {
              fluid(maxWidth: 960, maxHeight: 540) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
        pairing {
          childMarkdownRemark {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
        ingredients
        yield
        prep_time
        cooking_time
        difficulty
        related_recipes {
          childMarkdownRemark {
            id
            fields {
              slug
            }
            frontmatter {
              title
              featured_image {
                src {
                  publicURL
                  childImageSharp {
                    fixed(width: 320, height: 200) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                alt
              }
            }
          }
        }
      }
    }
  }
`
